import React from 'react';

import Layout from '../components/Layout';

//intoductions
import picPosition from '../assets/images/landscapes/village.jpg';
import picEvent from '../assets/images/landscapes/cabane_alpage.jpg';

//pics partners
import picAgribio from '../assets/images/actors/agribio.jpg';
import picPaysans from '../assets/images/actors/paysans.png';
import picSapn from '../assets/images/actors/sapn.jpg';
import picGap from '../assets/images/actors/gap.jpg';
import picCirque from '../assets/images/actors/aurelie.jpg';
import picRemerciement from '../assets/images/landscapes/cabane.png';
import picStephanie from '../assets/images/actors/stephanie_bodet.jpg';
import picVincent from '../assets/images/actors/vincent_meunier.jpg';
import picJean from '../assets/images/actors/noblet.jpg';

import picInterview from '../assets/images/actors/noblet.jpg';
import picYouth from '../assets/images/actors/youth.png';
import picTerre from '../assets/images/actors/terre.jpg';
import picPomme from '../assets/images/actors/pommes.jpg';
import picEcrin from '../assets/images/actors/ecrin.jpg';
import picLpo from '../assets/images/actors/lpo.png';

import config from '../../config';

//main Part

const mainTitle = 'Événements "à part"'
const mainDescription = "Organisation, production, création d’événements artistiques, concerts, résidences, stages..."

//about Part

const aboutTitle = "A propos"
const aboutDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. "

//agenda Part

const pressTitle = "Presse & Partenaires"
const pressDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. "

const agendaTitle = "A venir"
const agendaDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. "

const projectTitle = "Nos projets"
const projectDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. "

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{mainTitle}</h2>
        <p>{mainDescription}</p>
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/association" className="image">
            <img src={picPosition} alt="" />
          </a>
          <div className="content">
            <h2 className="major">{aboutTitle}</h2>
            <p>{aboutDescription}</p>
            <a href="/soutenir" className="special">
              Soutenir l'association
            </a>
            <a href="/association" className="special">
              En savoir plus
            </a>
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/agenda" className="image">
            <img src={picEvent} alt="" />
          </a>
          <div className="content">
            <h2 className="major">{agendaTitle}</h2>
            <p>{agendaDescription}</p>
            <a href="/agenda" className="special">
              Consulter l'agenda 
            </a>
          </div>
        </div>
      </section>

      <section id="three" className="wrapper style1">
        <div className="inner">
          <h2 className="major">{projectTitle}</h2>
          <p>
            {projectDescription}
          </p>
          <section className="features" id="actors">

            <article>
              <a href="/concerts" className="image">
                <img src={picCirque} alt="" />
              </a>
              <h3 className="major">Concert A Part</h3>
              <p>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem.
              </p>
              <a href="/concerts"  className="special">
                En savoir plus
              </a>
            </article>

            <article>
              <a href="/mouvement"  className="image">
                <img src={picCirque} alt="" />
              </a>
              <h3 className="major">Mouvement A Part</h3>
              <p>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem.
              </p>
              <a href="/mouvement" className="special">
                En savoir plus
              </a>
            </article>
            
            <article>
              <a href="/stages"  className="image">
                <img src={picCirque} alt="" />
              </a>
              <h3 className="major">Stages & Ateliers</h3>
              <p>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem.
              </p>
              <a href="/stages" className="special">
                En savoir plus
              </a>
            </article>
            
            <article>
              <a href="/accompagnement"  className="image">
                <img src={picCirque} alt="" />
              </a>
              <h3 className="major">Accompagnement</h3>
              <p>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem.
              </p>
              <a href="/accompagnement" className="special">
                En savoir plus
              </a>
            </article>


          </section>
        </div>
      </section>

      <section id="four" className="wrapper spotlight alt style3">
        <div className="inner">
          <a href="/presse" className="image">
            <img src={picInterview} alt="" />
          </a>
          <div className="content">
            <h2 className="major">{pressTitle}</h2>
            <p>
              {pressDescription}
            </p>
            <a href="/presse" className="special">
              Ils parlent de nous
            </a>
          </div>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
